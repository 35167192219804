@import "../src/styles/aem-uswds.css";
@import "../src/styles/variables/_generated.scss";
// @import "../src/styles/variables/_typography.scss";
@import "../src/styles/variables/_standards.scss";
@import "../src/styles/variables/_colors.scss";
@import "../src/styles/variables/_buttons.scss";
@import "../src/styles/_animations.scss";
// @import "../src/styles/_typography.scss";
@import "../src/styles/_buttons.scss";
@import "../src/styles/_highlight.scss";
@import "../src/styles/_offset-border.scss";
@import "../src/styles/_spacing.scss";
@import "../src/styles/_utilities.scss";

.usa-prose p {
    margin:0;
}

.remove-margin-from-immediate-children {
    > *{
        margin:0;
    }
}

.usa-section {
    position: relative;
}

@media all and (min-width: 64em) {
    .skipto{
        display: block !important;
    }

}

.skipto{
    z-index: 99999;
    display:none;
}

.usa-select {
    background-image: url(../src/assets/unfold.svg), linear-gradient(transparent, transparent);
    option {
        background-color: var(--usa-body-background);
        color: var(--usa-ink);
    }
}

.usa-footer__primary-content, .usa-footer__nav {
    border:none;
}



.top-left-triangle{


    &:before{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 0px 25px 75px;
        border-color: transparent  transparent  transparent var(--left-tri-color);
        left: 0;
        top: 0;
        position: absolute;
    }

}

.bottom-right-triangle{


    &:after{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 0px 25px 75px;
        border-color: transparent  transparent var(--right-tri-color)  transparent ;
        right: 0;
        bottom: 0;
        position: absolute;
    }


}

.top-right-triangle{


    &:before{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 75px 35px 0px;
        border-color: transparent  var(--left-tri-color) transparent  transparent ;
        right: 0;
        top: 0;
        position: absolute;
    }

}

.bottom-left-triangle{


    &:after{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 75px 0px 0px 35px;
        border-color: transparent  transparent   transparent var(--right-tri-color) ;
        left: 0;
        bottom: 0;
        position: absolute;
    }


}

.fade-in {
    animation: fadeIn ease-out .35s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media all and (min-width: 1441px) {

    .top-left-triangle{


        &:before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0px 0px 100px 200px;
            border-color: transparent  transparent  transparent var(--left-tri-color);
            left: 0;
            top: 0;
            position: absolute;
        }
    
    }
    
    .bottom-right-triangle{
    
    
        &:after{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0px 0px 100px 200px;
            border-color: transparent  transparent var(--right-tri-color)  transparent ;
            right: 0;
            bottom: 0;
            position: absolute;
        }
    
    
    }
    
    .top-right-triangle{
    
    
        &:before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0px 200px 100px 0px;
            border-color: transparent  var(--left-tri-color) transparent  transparent ;
            right: 0;
            top: 0;
            position: absolute;
        }
    
    }
    
    .bottom-left-triangle{
    
    
        &:after{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 100px 0px 0px 200px;
            border-color: transparent  transparent   transparent var(--right-tri-color) ;
            left: 0;
            bottom: 0;
            position: absolute;
        }
    
    
    }

}

[class^="bi-"].text-heavy::before, [class*=" bi-"].text-heavy::before {
        font-weight: 900 !important;
}

:root {
    --usa-link-visited-color: var(--usa-color-violet-vivid-30);
    --usa-link-active-color: var(--usa-primary-dark);
    --usa-link-hover-color: var(--usa-primary-light);
    --usa-link-color: var(--usa-primary-light);
}

a:-webkit-any-link {
    color: var(--usa-link-color);
    cursor: pointer;
    text-decoration: underline;
    &:visited {
        color: var(--usa-link-visited-color);
    }
    &:hover {
        color: var(--usa-link-hover-color);
    }
    &:active {
        color: var(--usa-link-active-color);
    }
}