// Utility classes specific to this project

.bg-library {
    background-color: var(--coi-library-primary);
}

.text-library {
    color: var(--coi-library-primary);
}

.bg-library-dark {
    background-color: var(--coi-library-dark);
}

.text-library-dark {
    color: var(--coi-library-dark);
}

.bg-library-light {
    background-color: var(--coi-library-light);
}

.text-library-light {
    color: var(--coi-library-light);
}

.bg-map {
    background-color: var(--coi-map-primary);
}

.text-map {
    color: var(--coi-map);
}

.bg-map-dark {
    background-color: var(--coi-map-dark);
}

.text-map-dark {
    color: var(--coi-map-dark);
}

.bg-map-light {
    background-color: var(--coi-map-light);
}

.text-map-light {
    color: var(--coi-map-light);
}

.bg-wiki {
    background-color: var(--coi-wiki-primary);
}

.text-wiki {
    color: var(--coi-wiki-primary);
}

.bg-wiki-dark {
    background-color: var(--coi-wiki-dark);
}

.text-wiki-dark {
    color: var(--coi-wiki-dark);
}

.bg-wiki-light {
    background-color: var(--coi-wiki-light);
}

.text-wiki-light {
    color: var(--coi-wiki-light);
}

.bg-arch {
    background-color: var(--coi-arch-primary);
}

.border-arch {
    border-color: var(--coi-arch-primary);

}

.text-arch {
    color: var(--coi-arch-primary);
}

.bg-arch-dark {
    background-color: var(--coi-arch-dark);
}

.text-arch-dark {
    color: var(--coi-arch-dark);
}

.bg-arch-light {
    background-color: var(--coi-arch-light);
}

.text-arch-light {
    color: var(--coi-arch-light);
}

.border-arch-dark {
    border-color: var(--coi-arch-dark);
}

.border-arch-light {
    border-color: var(--coi-arch-light);
}