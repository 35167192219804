
@keyframes slidein-left {
    0% {
        transform: translate(100%);
    }

    to {
        transform: translate(0);
    }
}
