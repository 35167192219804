:root {
  --usa-border-radius-0: var(--usa-sizing-0);
  --usa-border-radius-sm: var(--usa-sizing-05);
  --usa-border-radius-md: var(--usa-sizing-1);
  --usa-border-radius-lg: var(--usa-sizing-2);
  --usa-border-radius-pill: var(--usa-sizing-tablet);
  --usa-accordion-background-color: var(--usa-white);
  --usa-accordion-content-color: var(--usa-base-darkest);
  --usa-accordion-border-color: var(--usa-primary-lighter);
  --usa-accordion-border-width: var(--usa-border-width-lg);
  --usa-accordion-button-background: var(--usa-primary-lighter);
  --usa-accordion-button-color: var(--usa-base-darkest);
  --usa-accordion-button-active-background: var(--usa-primary-lighter);
  --usa-accordion-button-active-color: var(--usa-primary-darker);
  --usa-accordion-button-hover-background: var(--usa-primary-lighter);
  --usa-accordion-button-hover-color: var(--usa-primary-darker);
  --usa-accordion-button-icon-size: var(--usa-sizing-3);
  --usa-accordion-button-padding-y: var(--usa-spacing-2);
  --usa-accordion-button-padding-x: var(--usa-spacing-205);
  --usa-accordion-button-font-family: var(--usa-font-body);
  --usa-accordion-button-font-weight: var(--usa-font-weight-semibold);
  --usa-accordion-button-font-size: calc(var(--usa-body-font-size) * var(--a11y-font-size-modifier));
  --usa-accordion-button-line-height: calc(var(--usa-body-font-line-height) * var(--a11y-line-height-modifier));
  --usa-accordion-padding-y: var(--usa-spacing-2);
  --usa-accordion-padding-x: var(--usa-spacing-205);
  --usa-accordion-border-radius: var(--usa-border-radius-md);
  --usa-primary-lightest: #EEEFFB;
  --usa-primary-lighter: #D7D8EA;
  --usa-primary-light: #9C9CDE;
  --usa-primary-base: #565ADB;
  --usa-primary-vivid: #565ADB;
  --usa-primary-dark: #4247AC;
  --usa-primary-darker: #1D1F49;
  --usa-primary-darkest: #1E1E1E;
  --usa-button-color: var(--usa-ink);
  --usa-button-color-hover: var(--usa-ink);
  --usa-button-color-active: var(--usa-ink);
  --usa-button-background: var(--usa-primary);
  --usa-button-background-hover: var(--usa-primary-dark);
  --usa-button-background-active: var(--usa-primary-dark);
  --usa-button-inverse-color: var(--usa-link-reverse-color);
  --usa-button-inverse-hover-color: var(--usa-link-reverse-hover-color);
  --usa-button-inverse-active-color: var(--usa-link-reverse-active-color);
  --usa-button-font-family: var(--usa-font-ui);
  --usa-button-font-size: calc(var(--usa-font-size-md) * var(--a11y-font-size-modifier));
  --usa-button-line-height: var(--usa-line-height-3);
  --usa-button-lg-font-size: calc(var(--usa-font-size-lg) * var(--a11y-font-size-modifier));
  --usa-button-font-weight: var(--usa-font-weight-medium);
  --usa-button-border-radius: var(--usa-border-radius-sm);
  --usa-button-lg-border-radius: var(--usa-border-radius-lg);
  --usa-button-small-width: var(--usa-spacing-6);
  --usa-button-stroke-width: var(--usa-border-width-md);
  --usa-button-outline-offset: var(--usa-focus-offset);
  --usa-button-padding-x: var(--usa-spacing-205);
  --usa-button-padding-y: var(--usa-spacing-105);
  --usa-button-lg-padding-x: var(--usa-spacing-205);
  --usa-button-lg-padding-y: var(--usa-spacing-105);
  --usa-button-margin-right: var(--usa-spacing-0);
  --usa-button-icon-size: var(--usa-sizing-205);
  --usa-button-lg-icon-size: var(--usa-sizing-3);
  --usa-button-outline-background: var(--usa-transparent);
  --usa-button-outline-background-hover: var(--usa-transparent);
  --usa-button-outline-background-active: var(--usa-transparent);
  --usa-button-outline-color: var(--usa-primary-light);
  --usa-button-outline-hover-color: var(--usa-primary-lighter);
  --usa-button-outline-active-color: var(--usa-primary-lighter);
  --usa-button-outline-border-color: var(--usa-primary-dark);
  --usa-button-outline-border-hover-color: var(--usa-primary-base);
  --usa-button-outline-border-active-color: var(--usa-primary-base);
  --usa-font-size-3xs: calc(14px * var(--a11y-font-size-modifier));
  --usa-font-size-2xs: calc(14px * var(--a11y-font-size-modifier));
  --usa-font-size-xs: calc(15px * var(--a11y-font-size-modifier));
  --usa-font-size-sm: calc(16px * var(--a11y-font-size-modifier));
  --usa-font-size-md: calc(18px * var(--a11y-font-size-modifier));
  --usa-font-size-lg: calc(24px * var(--a11y-font-size-modifier));
  --usa-font-size-xl: calc(36px * var(--a11y-font-size-modifier));
  --usa-font-size-2xl: calc(48px * var(--a11y-font-size-modifier));
  --usa-font-size-3xl: calc(56px * var(--a11y-font-size-modifier));
  --usa-line-height-1: calc(1 * var(--a11y-line-height-modifier));
  --usa-line-height-2: calc(1.25 * var(--a11y-line-height-modifier));
  --usa-line-height-3: calc(1.38 * var(--a11y-line-height-modifier));
  --usa-line-height-4: calc(1.5 * var(--a11y-line-height-modifier));
  --usa-line-height-5: calc(1.65 * var(--a11y-line-height-modifier));
  --usa-line-height-6: calc(1.75 * var(--a11y-line-height-modifier));
  --usa-font-sans: 'Montserrat', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  --usa-font-serif: Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif;
  --usa-font-mono: 'Roboto Mono Web', "Bitstream Vera Sans Mono", "Consolas", "Courier", monospace;
  --usa-font-heading: var(--usa-font-sans);
  --usa-font-ui: var(--usa-font-sans);
  --usa-font-code: var(--usa-font-mono);
  --usa-font-alt: var(--usa-font-serif);
  --usa-font-prose: var(--usa-font-sans);
  --usa-font-weight-heavy: 900;
  --usa-font-weight-bold: 700;
  --usa-font-weight-semibold: 600;
  --usa-font-weight-medium: 400;
  --usa-font-weight-normal: 300;
  --usa-font-weight-light: 100;
  --usa-font-weight-thin: 100;
  --usa-body-font-size: calc(var(--usa-font-size-md) * var(--a11y-font-size-modifier));
  --usa-body-font-family: var(--usa-font-sans);
  --usa-body-font-weight: var(--usa-font-weight-normal);
  --usa-body-font-line-height: var(--usa-line-height-4);
  --usa-body-font-letter-spacing: calc(var(--usa-letter-spacing-1) * var(--a11y-letter-spacing-modifier));
  --usa-h1-font-size: calc(var(--usa-font-size-2xl) * var(--a11y-font-size-modifier));
  --usa-h1-font-family: var(--usa-font-heading);
  --usa-h1-font-weight: var(--usa-font-weight-semibold);
  --usa-h1-font-line-height: var(--usa-line-height-2);
  --usa-h1-font-letter-spacing: calc(var(--usa-letter-spacing-1) * var(--a11y-letter-spacing-modifier));
  --usa-h2-font-size: calc(var(--usa-font-size-xl) * var(--a11y-font-size-modifier));
  --usa-h2-font-family: var(--usa-font-heading);
  --usa-h2-font-weight: var(--usa-font-weight-semibold);
  --usa-h2-font-line-height: var(--usa-line-height-3);
  --usa-h2-font-letter-spacing: calc(var(--usa-letter-spacing-1) * var(--a11y-letter-spacing-modifier));
  --usa-h3-font-size: calc(var(--usa-font-size-lg) * var(--a11y-font-size-modifier));
  --usa-h3-font-family: var(--usa-font-heading);
  --usa-h3-font-weight: var(--usa-font-weight-medium);
  --usa-h3-font-line-height: var(--usa-line-height-3);
  --usa-h3-font-letter-spacing: calc(var(--usa-letter-spacing-1) * var(--a11y-letter-spacing-modifier));
  --usa-h4-font-size: calc(var(--usa-font-size-md) * var(--a11y-font-size-modifier));
  --usa-h4-font-family: var(--usa-font-heading);
  --usa-h4-font-weight: var(--usa-font-weight-semibold);
  --usa-h4-font-line-height: var(--usa-line-height-3);
  --usa-h4-font-letter-spacing: calc(var(--usa-letter-spacing-1) * var(--a11y-letter-spacing-modifier));
  --usa-h5-font-size: calc(var(--usa-font-size-sm) * var(--a11y-font-size-modifier));
  --usa-h5-font-family: var(--usa-font-heading);
  --usa-h5-font-weight: var(--usa-font-weight-semibold);
  --usa-h5-font-line-height: var(--usa-line-height-3);
  --usa-h5-font-letter-spacing: calc(var(--usa-letter-spacing-1) * var(--a11y-letter-spacing-modifier));
  --usa-h6-font-size: calc(var(--usa-font-size-2xs) * var(--a11y-font-size-modifier));
  --usa-h6-font-family: var(--usa-font-sans);
  --usa-h6-font-weight: var(--usa-font-weight-normal);
  --usa-h6-font-line-height: var(--usa-line-height-3);
  --usa-h6-font-letter-spacing: calc(var(--usa-letter-spacing-2) * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-0: calc(0em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-1: calc(0.025em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-2: calc(0.1em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-3: calc(0.15em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-neg-3: calc(-0.03em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-neg-2: calc(-0.02em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-neg-1: calc(-0.01em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-auto: calc(initial * var(--a11y-letter-spacing-modifier));
  --usa-lead-font-size: calc(var(--usa-font-size-lg) * var(--a11y-font-size-modifier));
  --usa-lead-font-family: var(--usa-body-font-family);
  --usa-lead-font-weight: var(--usa-font-weight-normal);
  --usa-lead-font-line-height: var(--usa-body-font-line-height);
  --usa-lead-font-letter-spacing: calc(var(--usa-letter-spacing-1) * var(--a11y-letter-spacing-modifier));
  --usa-lead-font-max-width: var(--usa-measure-small);
  --usa-body-background: var(--usa-base-darkest);
  --usa-body-ink: var(--usa-white);
  --usa-table-border-width: var(--usa-spacing-2px);
  --usa-table-cell-compact-padding-x: var(--usa-spacing-1);
  --usa-table-cell-compact-padding-y: var(--usa-spacing-05);
  --usa-table-cell-padding-x: var(--usa-spacing-2);
  --usa-table-cell-padding-y: var(--usa-spacing-1);
  --usa-table-unsorted-icon-hover-color: var(--usa-base-dark);
  --usa-table-unsorted-icon-color: var(--usa-base);
  --usa-table-sorted-stripe-background-color: var(--usa-accent-cool-lighter);
  --usa-table-sorted-background-color: var(--usa-accent-cool-lighter);
  --usa-table-sorted-header-background-color: var(--usa-accent-cool-light);
  --usa-table-text-hover-color: var(--usa-base-dark);
  --usa-table-stripe-background-color: var(--usa-base-darker);
  --usa-table-header-background-color: var(--usa-base-lighter);
  --usa-table-border-color: var(--usa-body-ink);
  --usa-table-sorted-icon-color: var(--usa-table-text-color);
  --usa-table-sorted-header-text-color: var(--usa-table-text-color);
  --usa-table-text-color: var(--usa-body-ink);
  --usa-table-stripe-text-color: var(--usa-table-text-color);
  --usa-table-header-text-color: var(--usa-table-text-color);
  --usa-table-sorted-text-color: var(--usa-table-text-color);
  --usa-table-font-size: calc(var(--usa-font-size-sm) * var(--a11y-font-size-modifier));
  --usa-table-font-family: var(--usa-font-body);
  --usa-table-line-height: var(--usa-line-height-2);
  --usa-table-font-weight: var(--usa-font-weight-normal);
  --usa-table-header-font-size: calc(var(--usa-font-size-sm) * var(--a11y-font-size-modifier));
  --usa-table-header-font-family: var(--usa-font-body);
  --usa-table-header-font-weight: var(--usa-font-weight-semibold);
  --usa-table-header-line-height: var(--usa-line-height-3);
  --usa-table-margin-y: var(--usa-spacing-205);
  --usa-table-caption-margin-bottom: var(--usa-spacing-105);
  --usa-table-caption-font-size: calc(var(--usa-font-size-xs) * var(--a11y-font-size-modifier));
  --usa-table-caption-font-family: var(--usa-font-body);
  --usa-table-caption-font-weight: var(--usa-font-weight-bold);
  --usa-table-caption-text-align: left;
  --usa-table-caption-line-height: var(--usa-line-height-3);
  --usa-sidenav-item-font-size: calc(var(--usa-font-size-md) * var(--a11y-font-size-modifier));
  --usa-sidenav-item-font-family: var(--usa-font-sans);
  --usa-sidenav-item-font-weight: var(--usa-font-weight-normal);
  --usa-sidenav-item-font-weight-current: var(--usa-font-weight-medium);
  --usa-sidenav-item-line-height: var(--usa-line-height-3);
  --usa-sidenav-item-text-align: right;
  --usa-sidenav-sublist-font-size: calc(var(--usa-font-size-2xs) * var(--a11y-font-size-modifier));
  --usa-sidenav-sublist-font-weight: var(--usa-sidenav-item-font-weight);
  --usa-sidenav-sublist-font-weight-current: var(--usa-sidenav-item-font-weight-current);
  --usa-sidenav-sublist-line-height: var(--usa-sidenav-item-line-height);
  --usa-sidenav-sublist-font-family: var(--usa-sidenav-item-font-family);
  --usa-sidenav-item-background: var(--usa-body-background);
  --usa-sidenav-item-background-hover: var(--usa-black);
  --usa-sidenav-item-background-current: var(--usa-sidenav-item-background);
  --usa-sidenav-sublist-background: var(--usa-sidenav-item-background);
  --usa-sidenav-sublist-background-hover: var(--usa-sidenav-item-background-hover);
  --usa-sidenav-sublist-background-current: var(--usa-sidenav-item-background-current);
  --usa-sidenav-item-border-color: var(--usa-border-color-base);
  --usa-sidenav-item-border-width: var(--usa-border-width-0);
  --usa-sidenav-sublist-border-color: var(--usa-sidenav-item-border-color);
  --usa-sidenav-sublist-border-width: var(--usa-sidenav-item-border-width);
  --usa-sidenav-item-padding-x: var(--usa-spacing-2);
  --usa-sidenav-item-padding-y: var(--usa-spacing-1);
  --usa-sidenav-item-margin-bottom: var(--usa-spacing-0);
  --usa-sidenav-item-margin-top: var(--usa-spacing-0);
  --usa-sidenav-item-color: var(--usa-ink);
  --usa-sidenav-item-color-hover: var(--usa-primary-lighter);
  --usa-sidenav-item-color-current: var(--usa-primary-light);
  --usa-sidenav-item-text-decoration: none;
  --usa-sidenav-sublist-item-color: var(--usa-sidenav-item-color);
  --usa-sidenav-sublist-item-color-hover: var(--usa-sidenav-item-color-hover);
  --usa-sidenav-sublist-item-color-current: var(--usa-sidenav-item-color-current);
  --usa-sidenav-sublist-item-text-decoration: var(--usa-sidenav-item-text-decoration);
  --usa-sidenav-item-focus-outline-offset: 0;
  --usa-sidenav-item-current-indicator-color: var(--usa-primary);
  --usa-sidenav-item-current-indicator-border-radius: var(--usa-border-radius-pill);
  --usa-sidenav-item-current-indicator-width: var(--usa-sizing-05);
  --usa-sidenav-item-current-indicator-position: 100%;
  --usa-sidenav-child-padding-left: var(--usa-spacing-0);
  --usa-sidenav-child-padding-right: var(--usa-spacing-2px);
  --usa-sidenav-child-padding-multiplier: 2;
  --usa-input-font-family: var(--usa-font-ui);
  --usa-input-font-size: calc(var(--usa-body-font-size) * var(--a11y-font-size-modifier));
  --usa-input-font-weight: var(--usa-font-weight-medium);
  --usa-input-line-height: var(--usa-line-height-3);
  --usa-input-letter-spacing: calc(var(--usa-body-font-letter-spacing) * var(--a11y-letter-spacing-modifier));
  --usa-input-height: var(--usa-sizing-6);
  --usa-input-select-size: var(--usa-sizing-3);
  --usa-input-select-caret-color: var(--usa-input-color);
  --usa-input-max-width: var(--usa-sizing-mobile-lg);
  --usa-input-margin-top: var(--usa-spacing-1);
  --usa-input-padding-x: var(--usa-spacing-2);
  --usa-input-padding-y: var(--usa-spacing-1);
  --usa-input-large-height: var(--usa-sizing-7);
  --usa-input-border-width: var(--usa-border-width-0);
  --usa-input-border-color: var(--usa-border-color-base);
  --usa-input-border-color-hover: var(--usa-border-color-base);
  --usa-input-color: var(--usa-black);
  --usa-input-placeholder-color: var(--usa-base-dark);
  --usa-input-background-color: var(--usa-base-lighter);
  --usa-input-border-radius: var(--usa-border-radius-md);
  --usa-input-search-min-width: 27ch;
  --usa-input-disabled-border-color: var(--usa-disabled-light);
  --usa-input-disabled-border-color-hover: var(--usa-input-disabled-border-color);
  --usa-input-disabled-color: var(--usa-disabled);
  --usa-input-disabled-background-color: var(--usa-disabled-lighter);
  --usa-input-label-font-size: calc(var(--usa-font-size-md) * var(--a11y-font-size-modifier));
  --usa-input-label-font-family: var(--usa-font-ui);
  --usa-input-label-font-weight: var(--usa-font-weight-medium);
  --usa-input-label-color: var(--usa-base-dark);
  --usa-input-label-line-height: var(--usa-line-height-3);
  --usa-input-label-margin-bottom: var(--usa-spacing-0);
  --usa-input-label-margin-top: var(--usa-spacing-0);
  --usa-base-lightest: #f5f5f5;
  --usa-base-lighter: #e1e1e1;
  --usa-base-light: #bdbdbd;
  --usa-base-base: #9e9e9e;
  --usa-base-dark: #616161;
  --usa-base-darker: #424242;
  --usa-base-darkest: #1E1E1E;
  --usa-radio-size: var(--usa-sizing-205);
  --usa-radio-background-color: var(--usa-body-background-color);
  --usa-radio-label-color: var(--usa-ink);
  --usa-radio-border-color: var(--usa-base-dark);
  --usa-radio-border-width: var(--usa-border-width-sm);
  --usa-radio-border-radius: var(--usa-border-radius-pill);
  --usa-radio-checked-background-color: var(--usa-secondary);
  --usa-radio-checked-border-color: var(--usa-secondary);
  --usa-radio-focus-border-color: var(--usa-input-focus-border-color);
  --usa-radio-focus-border-width: var(--usa-input-focus-border-width);
  --usa-radio-disabled-background-color: var(--usa-input-disabled-background-color);
  --usa-radio-disabled-border-color: var(--usa-input-disabled-border-color);
  --usa-radio-disabled-color: var(--usa-input-disabled-color);
  --usa-radio-tile-padding-y: var(--usa-spacing-2);
  --usa-radio-tile-background-color: var(--usa-radio-background-color);
  --usa-radio-tile-label-color: var(--usa-radio-label-color);
  --usa-radio-tile-border-radius: var(--usa-input-border-radius);
  --usa-radio-tile-border-color: var(--usa-radio-border-color);
  --usa-radio-tile-border-width: var(--usa-radio-border-width);
  --usa-radio-tile-checked-background-color: var(--usa-primary-lightest);
  --usa-radio-tile-checked-border-color: var(--usa-radio-checked-border-color);
  --usa-secondary-lightest: #FFFFB8;
  --usa-secondary-lighter: #FFFF9A;
  --usa-secondary-light: #FFEA7C;
  --usa-secondary-base: #E1CC5E;
  --usa-secondary-vivid: #E1CC5E;
  --usa-secondary-dark: #C3AE40;
  --usa-secondary-darker: #A59022;
  --usa-secondary-darkest: #877204;
  
  }