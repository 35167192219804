.coi-highlight {
    background-color: var( --usa-accent-cool-light);
    color: var(--usa-base-darkest);
    font-weight: var(--usa-font-weight-semibold);
    &::selection {
        background: var(--usa-color-cyan-vivid-10);
      }
}

h1.coi-highlight,
h2.coi-highlight,
h3.coi-highlight,
h4.coi-highlight,
h5.coi-highlight {
    padding: var(--usa-spacing-05) var(--usa-spacing-1);
}