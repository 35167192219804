.gap-0 {
    gap: var(--usa-spacing-0);
}

.gap-1px {
    gap: var(--usa-spacing-1px);
}

.gap-2px {
    gap: var(--usa-spacing-2px);
}

.gap-05, .gap-sm {
    gap: var(--usa-spacing-05);
}

.gap, .gap-1 {
    gap: var(--usa-spacing-1);
}

.gap-105, .gap-md {
    gap: var(--usa-spacing-105);
}

.gap-2, .gap-lg {
    gap: var(--usa-spacing-2);
}

.gap-205 {
    gap: var(--usa-spacing-205);
}

.gap-3 {
    gap: var(--usa-spacing-3);
}

.gap-4 {
    gap: var(--usa-spacing-4);
}

.gap-5 {
    gap: var(--usa-spacing-5);
}

.gap-6 {
    gap: var(--usa-spacing-6);
}
.gap-7 {
    gap: var(--usa-spacing-7);
}
.gap-8 {
    gap: var(--usa-spacing-8);
}


@media all and (min-width: 40em) {

.tablet\:grid-row{
    display: flex;
    flex-wrap: wrap;
}


.tablet\:grid-col-3{
    flex:0 1 auto;width:25%;width:var(--usa-spacing-override,25%)
}


.tablet\:grid-col-8{
    flex:0 1 auto;width:66.6666666667%;width:var(--usa-spacing-override,66.6666666667%)
}

// .tablet\:grid-col-5{
//     flex:0 1 auto;width:41.6666666667%;width:var(--usa-spacing-override,41.6666666667%)
// }

.tablet\:flex-align-self-start{
    align-self:flex-start
}

}