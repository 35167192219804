.offset-border {
    position: relative;
    z-index:1;

    &:before {
        content: "";
        position: absolute;
        border: var(--usa-spacing-05) solid var(--usa-primary-light);
        z-index: -1;
        max-width: inherit;
    }

    &.offset-top-left {
        &:before {
            top: var(--usa-spacing-neg-2);
            left: var(--usa-spacing-neg-2);
            right: var(--usa-spacing-2);
            bottom: var(--usa-spacing-2);
        }
    }

    &.offset-top-right {
        &:before {
            top: var(--usa-spacing-neg-2);
            right: var(--usa-spacing-neg-2);
            left: var(--usa-spacing-2);
            bottom: var(--usa-spacing-2);
        }
    }

    &.offset-bottom-left {
        &:before {
            bottom: var(--usa-spacing-neg-2);
            left: var(--usa-spacing-neg-2);
            right: var(--usa-spacing-2);
            top: var(--usa-spacing-2);
        }
    }

    &.offset-bottom-right {
        &:before {
            bottom: var(--usa-spacing-neg-2);
            right: var(--usa-spacing-neg-2);
            left: var(--usa-spacing-2);
            top: var(--usa-spacing-2);
        }
    }
}