.usa-button {
    // font-family: var(--usa-button-font-family);
    // font-size: var(--usa-button-font-size);
    // line-height: var(--usa-button-line-height);
    // color: var(--usa-white);
    // background-color: var(--usa-primary-dark);
    // -webkit-appearance: none;
    // appearance: none;
    // border: 0;
    // border-radius: var(--usa-button-border-radius);
    // cursor: pointer;
    // display: flex;
    // gap: var(--usa-spacing-1);
    // font-weight: var(--usa-button-font-weight);
    // margin-right: var(--usa-button-margin-right);
    // padding: var(--usa-button-padding-y) var(--usa-button-padding-x);
    // text-align: center;
    // text-decoration: none;
    // width: auto;
    // align-items: center;
    transition: all var(--coi-short-transition-duration) var(--coi-standard-transition-easing);

    @media all and (min-width: 30em) {
        width: auto;
    }
}
