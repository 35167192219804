:root {
    --coi-standard-transition-duration: .2s;
    --coi-short-transition-duration: .125s;
    --coi-standard-transition-easing: ease;
}

::selection {
    background: var(--usa-color-cyan-vivid-70);
  }

  .dark-text-selector {
    ::selection {
        background: var(--usa-color-cyan-vivid-10);
      }
  }