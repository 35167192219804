:root {

    // Semantic color aliases go here

    // --usa-primary-darkest: hsl(237, 43%, 20%);
    // --usa-primary-darker: hsl(237, 45%, 45%);
    // --usa-primary-dark: hsl(238, 65%, 53%);
    // --usa-primary-vivid: hsl(240, 50%, 78%);
    // --usa-primary-base: hsl(240, 50%, 78%);
    // --usa-primary-light: hsl(237, 31%, 89%);
    // --usa-primary-lighter: hsl(235, 62%, 97%);
    // --usa-primary-lightest: false;

    --usa-accent-cool: #E1CC5E;
    --usa-accent-cool-dark: #BDA423;
    --usa-accent-cool-light: #FAF2C7;

    --usa-yellow: var(--usa-accent-cool-light);

    // --usa-base-ink: hsl(0, 0%, 12%);

    --usa-text-color: var(--usa-primary-lighter);
    --usa-text-reverse-color: var(--usa-color-global-white);

    --coi-library: hsl(50,69%,63%);
    --coi-library-primary: hsl(50,69%,63%);
    --coi-library-dark:hsl(50,69%,44%);
    --coi-library-light: hsl(50,84%,88%);
    --usa-library: var(--coi-library-primary);
    --usa-library-primary: var(--coi-library-primary);
    --usa-library-dark: var(--coi-library-dark);
    --usa-library-light: var(--coi-library-light);

    --coi-map: hsl(126,35%,60%);
    --coi-map-primary: hsl(126,35%,60%);
    --coi-map-dark:hsl(126,35%,44%);
    --coi-map-light: hsl(126,35%,94%);
    --usa-map: var(--coi-map-primary);
    --usa-map-primary: var(--coi-map-primary);
    --usa-map-dark: var(--coi-map-dark);
    --usa-map-light: var(--coi-map-light);

    --coi-wiki: hsl(29,67%,67%);
    --coi-wiki-primary: hsl(29,67%,67%);
    --coi-wiki-dark:hsl(29,67%,44%);
    --coi-wiki-light: hsl(29,67%,90%);
    --usa-wiki: var(--coi-wiki-primary);
    --usa-wiki-primary: var(--coi-wiki-primary);
    --usa-wiki-dark: var(--coi-wiki-dark);

    --coi-arch: hsl(184,48%,55%);
    --coi-arch-primary: hsl(184,48%,55%);
    --coi-arch-dark:hsl(184,49%,44%);
    --coi-arch-light: hsl(184,49%,93%);
    --usa-arch: var(--coi-arch-primary);
    --usa-arch-primary: var(--coi-arch-primary);
    --usa-arch-dark: var(--coi-arch-dark);

    --usa-cm-dark: #BB98C8;
}